import React from 'react'
import { Row, Col, Badge} from 'reactstrap'

const InfoWidget = ({size='lg', title=true}) =>{
    return <>
    {title && <Row>
        <Col>
        <h2 className="text-center" style={{padding:'1em'}}><em>Guide e informazioni FAD</em></h2>
        </Col>
    </Row>}
    <Row>
        <Col md={ size==='lg' ? 4 : 12}>
            <div className="card shadow-sm">
                <div className="card-header">
                    <h4 className="text-info "><i className="fa fa-chalkboard"></i> <small>FAD /</small> Studenti</h4>
                </div>
                <div className="card-body">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.cloud.microsoft/kjtu1CZXKGTkUwUZ?ref=Link" target="_blank"><b>[AA 24_25]</b> Domanda Valutazione Preventiva su Esse3 - Studente <Badge color="warning">New</Badge></a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/WhPcexXPMk8VW3B0?ref=Link" target="_blank"><b>[AA 21_22]</b> Linee guida per lezioni</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/EsiIUgImr4ZVb7yX?ref=Link" target="_blank"><b>[ESSE3]</b> Istruzioni per ricevere le credenziali proprietarie di ESS3 <Badge color="danger">Importante</Badge></a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/fYYq8IBABdoPEw7h?ref=Link" target="_blank"><b>[UNIMESTONE]</b> Guida all'uso della piattaforma</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/24leGrhlhGzEpCOU?ref=Link" target="_blank"><b>[UNIMESTONE]</b> User Guide</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/eeWiLuc9Uz5d19Z3?ref=Link" target="_blank"><b>[AA 20_21]</b> Account @studenti.unime.it</a> <Badge color="danger">Importante</Badge></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/LHDuHHuZqDrGzd8O?ref=Link" target="_blank"><b>[AA 20_21]</b> Riattivazione temporanea Google Drive @studenti.unime.it</a> </li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/PusFZKfzdbhqW7GN?ref=Link" target="_blank"><b>[AA 20_21]</b> Inizio lezioni Blended - Guida studente</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/HBGej1vpo95NhUBw?ref=Link" target="_blank"><b>[AA 20_21]</b> Accesso alle strutture UniME</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/8KWl955yjJHU3Mcd?ref=Link" target="_blank"><b>[AA 20_21]</b> Student Booking - Guida studente</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://www.unime.it/sites/default/files/APP_Rilevazione_Frequenze.pdf" target="_blank"><b>[AA 20_21]</b> App Rilevazione frequenze - Guida studente</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/KinGK5nYVmehQVi8?ref=Link" target="_blank"><b>[ESSE3]</b> Esami in modalità MISTA - guida Studente</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/sTiodrCQ5PPUmc7v?ref=Link" target="_blank"><b>[TEAMS]</b> Accesso a Lezioni/Esami</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/qpwRqgejSjC9vcAv?ref=Link"><b>[TEAMS]</b> Attivazione account Microsoft</a> <Badge color="danger">Importante</Badge></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/0ZVnWTuQxYoOT1qF?ref=Link" target="_blank"><b>[ESSE3]</b> Caricamento del documento di identità su ESSE3</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/t2AUcXtxy1sN4rm2?ref=Link" target="_blank"><b>[SBA]</b> Accesso alle risorse bibliografiche SBA Unime</a></li>
                    </ul>
                </div>
            </div>
            <br/>
            <div className="card shadow-sm">
                <div className="card-header">
                    <h4 className="text-info"><i className="fa fa-book-open"></i> <small>TOOLS /</small> Guide</h4>
                </div>
                <div className="card-body">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/kxpjaePZ2HIvohML?ref=Link" target="_blank"><b>[TEAMS]</b> Creare una riunione con ospiti esterni</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/a7F4uxGtXfQeJnXu?ref=Link" target="_blank"><b>[TEAMS]</b> Esami su Teams - Linee Guida</a> <Badge color="danger">Importante</Badge></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/xS6mjZTMy9JLGtIO?ref=Link" target="_blank"><b>[OFFICE]</b> Unime e Microsoft 365</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/TmDPCb8tgidBd8bG?ref=Link" target="_blank"><b>[TEAMS]</b> Creare e gestire Eventi Live</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/6jOdDP1MHAC4gcxG?ref=Link" target="_blank"><b>[TEAMS]</b> Guida base personale TA</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/uXbih66ChCHEnj1f?ref=Link" target="_blank"><b>[VPN]</b> Configurazione OpenVPN - Windows</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/AwhwVC6DMMQWFRVn?ref=Link" target="_blank"><b>[VPN]</b> Configurazione TunnelBlick - Mac</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/uEqHeB4V5I3ES2b7?ref=Link" target="_blank"><b>[VPN]</b> Configurazione VPN - Linux Ubuntu</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/uBXdrtvIRyif5MXS?ref=Link" target="_blank"><b>[JABBER]</b> Richiesta di linea telefonica interna per postazione di telelavoro</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/shuQ3C0f39MIexD9?ref=Link" target="_blank"><b>[CISCO]</b> Attivazione segreteria telefonica</a></li>
                    </ul>
                </div>
            </div>
            <br/>
        </Col>
        <Col md={ size==='lg' ? 4 : 12}>
            <div className="card shadow-sm">
                <div className="card-header">
                    <h4 className="text-info "><i className="fa fa-chalkboard-teacher"></i> <small>FAD /</small> Docenti</h4>
                </div>
                <div className="card-body">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/QOzqQnPcL6nS9Wlw?ref=Link" target="_blank"><b>[AA 20_21]</b> Student Booking - Guida Docente</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://www.unime.it/sites/default/files/APP_Rilevazione_Frequenze.pdf" target="_blank"><b>[AA 20_21]</b> App Rilevazione frequenze - Guida Docente</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/cXAmlv7onQytFawA?ref=Link" target="_blank"><b>[ESSE3]</b> Esami in modalità MISTA - guida Docente</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/aOcnFrJPmM1a248q?ref=Link" target="_blank"><b>[TEAMS]</b> Guida base per avviare lezione</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/ArYcUddQuEcyUR9F?ref=Link" target="_blank"><b>[TEAMS]</b> Pianificare e moderare una riunione</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/n3fCdBkEYThI2YvQ?ref=Link" target="_blank"><b>[TEAMS]</b> Aggiungere utenti Ospiti (Guest) a Teams</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/7tnzaU2whbjCzELs?ref=Link" target="_blank"><b>[TEAMS]</b> Invito tramite collegamento o codice ad un team</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/EJ2adcZCTSzX0dtF?ref=Link" target="_blank"><b>[ESSE3]</b> Visualizzare documento di identità dello studente in sede di esame</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/PphutLyOsLDqbiRN?ref=Link" target="_blank"><b>[TEAMS]</b> Scaricare un rapporto sui partecipanti in una riunione di Teams</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/tv8cvx56aEJRUufH?ref=Link" target="_blank"><b>[GAIA]</b> Richiesta Nuovo Account G.A.I.A. <small>(Gestione Accessi Integrata di Ateneo)</small></a></li>
                    </ul>
                </div>
            </div>
            <br/>
            <div className="card shadow-sm">
                <div className="card-header">
                    <h4 className="text-info "><i className="fa fa-universal-access"></i> <small>ACCESSIBILITA' /</small> Tools</h4>
                </div>
                <div className="card-body">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/1dCYOxHPXfkW96FA?ref=Link" target="_blank"><b>[WORD]</b> Come attivare servizi di riconoscimento vocale in Microsoft Word – versione 365.</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/WiaRCU6PNxY61h4Z?ref=Link" target="_blank"><b>[MacOs]</b> Come attivare servizi di sintesi vocale integrati in macOS</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/yQqSblKkkIn30xfM?ref=Link" target="_blank"><b>[TEAMS]</b> Come rendere attive due istanze di Microsoft Teams in Windows 10</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/aFanGVd9ZOioPFZn?ref=Link" target="_blank"><b>[TEAMS]</b> Usare Microsoft Teams attraverso combinazioni di tasti</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/932wUnWViRvkgMmP?ref=Link" target="_blank"><b>[POWERPOINT]</b> Come convertire una presentazione PowerPoint in un PDF accessibile</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/hFsyzjbgrM3LEbM2?ref=Link" target="_blank"><b>[PDF]</b> Come verificare l'accessibilità di un file PDF</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/YhuOFc6K8exW9T8y?ref=Link" target="_blank"><b>[OUTLOOK]</b> Come attivare servizi di sintesi vocale in Outlook – versione 365</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/LzozBa5di8YpYqYP?ref=Link" target="_blank"><b>[WORD]</b> Come attivare servizi di sintesi vocale in Word – versione 365</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/6t6EBkxxangx4Adw?ref=Link" target="_blank"><b>[WORD]</b> Come convertire un documento in un file PDF accessibile usando Word – versione 365</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/BHYARHsAC6ghznGj?ref=Link" target="_blank"><b>[WORD]</b> Come effettuare una verifica dell’accessibilità di un documento Word – versione 365</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/SZTcaYDiwN0TPk7l?ref=Link" target="_blank"><b>[POWERPOINT]</b> Come rendere accessibile una immagine in una diapositiva di Microsoft PowerPoint 365</a></li>
                        <li className="list-group-item"><a rel="noopener noreferrer" href="https://sway.office.com/sDSFpoUKxJ9NEb69?ref=Link" target="_blank"><b>[POWERPOINT]</b> Generazione automatica di sottotitoli in una presentazione di Microsoft PowerPoint 365</a></li>
                    </ul>
                </div>
            </div>
            <br/>
            <div className="card shadow-sm">
                <div className="card-header">
                    <h4 className="text-info"><i className="fa fa-laptop-code"></i> Supporto Tecnologico - CIAM</h4>
                </div>
                <div className="card-body">
                <p>Le richieste di supporto devono essere inviate unicamente tramite i canali sotto indicati, quelle inviate via email all'indirizzo protocollo@unime.it saranno ignorate.<hr />
                Si consiglia l'uso del <b><a href="https://supporto-unime.atlassian.net/">Sistema di Ticketing d'Ateneo</a></b>
                 <br/> <i>(ad una eventuale richiesta di login, cliccare su "Continua con Microsoft")</i><br/><br/>
                 <p>N.B. Nelle richieste inviate tramite mail è <b>obbligatorio inserire l'oggetto del messaggio</b>. Le mail ricevute senza
                    oggetto non verranno prese in considerazione dal sistema di ticketing</p>
                 </p>
                <hr />
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><b>Gestione posta elettronica</b><br /><a rel="noopener noreferrer" href="mailto:postaelettronica@unime.it?Subject=fad.unime.it">postaelettronica@unime.it</a></li>
                        <li className="list-group-item"><b>Rete e VPN</b><br /><a rel="noopener noreferrer" href="mailto:supportorete@unime.it?Subject=fad.unime.it">supportorete@unime.it</a></li>
                        <li className="list-group-item"><b>G.A.I.A. (utenze unime.it / studenti.unime.it)</b><br /><a rel="noopener noreferrer" href="mailto:supportogaia@unime.it?Subject=fad.unime.it">supportogaia@unime.it</a></li>
                        <li className="list-group-item"><b>Sicurezza informatica</b><br /><a rel="noopener noreferrer" href="mailto:cert@unime.it?Subject=fad.unime.it">cert@unime.it</a></li>
                        <li className="list-group-item"><b>Supporto E-Learning (piattaforma Moodle)</b><br /><a rel="noopener noreferrer" href="mailto:elsupport@unime.it?Subject=fad.unime.it">elsupport@unime.it</a></li>
                        <li className="list-group-item"><b>Portale WEB</b><br /><a rel="noopener noreferrer" href="mailto:web@unime.it?Subject=fad.unime.it">web@unime.it</a></li>
                        <li className="list-group-item"><b>Dirette LIVE</b><br /><a rel="noopener noreferrer" href="mailto:diretteonline@unime.it?Subject=fad.unime.it">diretteonline@unime.it</a></li>
                    </ul>
                </div>
            </div>
            <br/>
        </Col>
        <Col md={ size==='lg' ? 4 : 12}>
            <div className="card shadow-sm">
                <div className="card-header">
                    <h4 className="text-info "><i className="fa fa-user-check"></i> <small>FAD /</small> Supporto mail dipartimenti <Badge color="warning">New</Badge></h4>
                </div>
                <div className="card-body">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><b>Civiltà antiche e moderne</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.dicam@unime.it?Subject=fad.unime.it">supporto.dicam@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Economia</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.economia@unime.it?Subject=fad.unime.it">supporto.economia@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Giurisprudenza</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.giurisprudenza@unime.it?Subject=fad.unime.it">supporto.giurisprudenza@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Ingegneria</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.ingegneria@unime.it?Subject=fad.unime.it">supporto.ingegneria@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Medicina clinica e sperimentale</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.dimed@unime.it?Subject=fad.unime.it">supporto.dimed@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Patologia umana dell'adulto e dell'età evolutiva "Gaetano Barresi"</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.patologiaumanadetev@unime.it?Subject=fad.unime.it">supporto.patologiaumanadetev@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Scienze biomediche, odontoiatriche e delle immagini morfologiche e funzionali</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.biomorf@unime.it?Subject=fad.unime.it">supporto.biomorf@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Scienze chimiche, biologiche, farmaceutiche ed ambientali</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.chibiofaram@unime.it?Subject=fad.unime.it">supporto.chibiofaram@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Scienze cognitive, psicologiche, pedagogiche e degli studi culturali</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.cospecs@unime.it?Subject=fad.unime.it">supporto.cospecs@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Scienze matematiche e informatiche, scienze fisiche e scienze della terra</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.mift@unime.it?Subject=fad.unime.it">supporto.mift@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Scienze politiche e giuridiche</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.scipog@unime.it?Subject=fad.unime.it">supporto.scipog@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><b>Scienze veterinarie</b><br />
                            <a rel="noopener noreferrer" href="mailto:supporto.veterinaria@unime.it?Subject=fad.unime.it">supporto.veterinaria@unime.it</a><br />
                        </li>
                        <li className="list-group-item"><i>Tali indirizzi sono attivi sul sistema di ticketing di ateneo</i>
                        <p>N.B. Nelle richieste inviate tramite mail è <b>obbligatorio inserire l'oggetto del messaggio</b>. Le mail ricevute senza
                    oggetto non verranno prese in considerazione dal sistema di ticketing</p>
                        </li>
                    </ul>

                </div>
            </div>
            <br/>
            <div className="card shadow-sm">
                <div className="card-header">
                    <h4 className="text-info"><i className="fa fa-info-circle"></i> Informazioni generali</h4>
                </div>
                <div className="card-body">
                    Per problemi generici gli studenti potranno rivolgersi a:
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><b>Numero Verde:</b><br />
                            800230842 <small>(da rete fissa)</small><br /> 056676210 <small>(da rete mobile)</small>
                        </li>
                        <li className="list-group-item"><b>Ufficio Relazioni con il pubblico:</b><br />
                            email <a href="mailto:urp@unime.it?Subject=fad.unime.it">urp@unime.it</a><br />
                            <a rel="noopener noreferrer" href="https://clienti.radiocallservice.it/unime/live-chat/" target="_blank">Live chat</a> <small><i>(servizio attivo dal lunedì al venerdì dalle ore 8:00 alle ore 18:00 e il sabato dalle ore 8:00 alle ore 14:00, esclusi i festivi)</i></small><br />
                            <a rel="noopener noreferrer" href="https://clienti.radiocallservice.it/unime/form_unime/" target="_blank">Contact Form</a>
                        </li>
                        <li className="list-group-item"><b>InfoPoint e servizi complementari agli studenti:</b><br />
                            email: <a  rel="noopener noreferrer" href="mailto:infopoint@unime.it?Subject=fad.unime.it">infopoint@unime.it</a><br/>
                            tel: 090-6768300 <small><i>(dal lunedì al giovedì dalle ore 09:00 alle ore 13:00 e dalle ore 14.30 alle ore 16:00; venerdì dalle ore 09:00 alle ore 13:00)</i></small>
                        </li>
                        <li className="list-group-item"><i>Tali indirizzi sono attivi sul sistema di ticketing di ateneo</i>
                        <p>N.B. Nelle richieste inviate tramite mail è <b>obbligatorio inserire l'oggetto del messaggio</b>. Le mail ricevute senza
                    oggetto non verranno prese in considerazione dal sistema di ticketing</p>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </Col>
    </Row>
    </>
}

export default InfoWidget
