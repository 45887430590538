import React from 'react';
import {Button, Badge, Card, CardBody, Row, Col, CardFooter,  Alert} from 'reactstrap';
import InfoWidget from './infoWidget';
import axios from 'axios'
import moment from 'moment'

const getInfo = async (info) => {
 
  return Promise.all(info.map(item => {
    if (item.path.startsWith('https')) return true
    return axios.get(`${item.url}?metaonly=true`)
            .then( res => {
              return {
                type: item.path, 
                count: res.data.data.count, 
                lastModified: moment.utc(res.data.data['last-modified'], "ddd, D MMM YYYY HH:mm:ss GMT").local().format('DD/MM/YYYY - [alle ore] HH:mm')
              }
            })
    }
    ))
}

const Welcome = ({info}) =>{

  const [state, setState] = React.useState ([])

  React.useEffect (  ()=>{
     getInfo(info)
     .then(t => setState(t)) 
  }, [info])

    return (
        <div>
            <Row style={{padding: 40}} className="justify-content-center">
            <Col md={3} xs={6} className="text-center" >
              <Button href="https://sway.office.com/PusFZKfzdbhqW7GN?ref=Link" color="info" style={{padding: 30}}><h3><i className="fa fa-user-graduate"></i> Guida Studente</h3></Button>
            </Col>
          </Row>
          <Row style={{paddingTop: 40}} className="justify-content-center">
            <Col md={12}>
              <h3 className="text-center">
                Formazione a Distanza UniMe
              </h3>
            </Col>
          </Row>
          <Row style={{paddingTop: 24}} className="justify-content-center">
            
              {info.map( item=> {
                
              return <Col xs={12} md={3} key={item.path}>
                <Card>
                  <CardBody>
                    <Alert color={item.text}>
                      <h6 className="text-muted"><small>FAD / {item.category}</small></h6>
                      <h4><a href={item.path.startsWith('https')?item.path:`#${item.path}`} className="alert-link">{item.title}</a></h4>
                    </Alert>
                    {state && state.count ? <>Aule attive: <Badge color="secondary" pill>{state.find( el => el.type === item.path).count }</Badge>
                    <br /><small>Aggiornato il {state.find( el => el.type === item.path).lastModified}</small></> : " "}
                  </CardBody>
                  <CardFooter>
                    <div className="text-center">
                      <a href={item.path.startsWith('https')?item.path:`#${item.path}`}><Button color="primary" size="sm" block>Visualizza Aule Attive</Button></a>
                    </div>
                  </CardFooter>
                  </Card>
                <br/>
              </Col>
              })}
             
          </Row>
            <InfoWidget />
      </div>
    )
}
 export default Welcome
