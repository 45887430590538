import React from 'react';
import { Route, HashRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { NavBarMenu, Welcome, Tabella, TabellaPaginata } from './components';
import menuItems from './config'
import ElencoLezioni from './components/elenco'

const hiddenInHome = menuItems.filter( item => (item.visibilityInHome !== "hidden" ))

const Components = {
  'ElencoLezioni' : ElencoLezioni,
}

const App = ()=> {
  return (<>
    <HashRouter>
      <NavBarMenu menuItems={hiddenInHome}/>
      <Container fluid={true}>
        <Route exact path={"/"}
          render={(props) =><Welcome {...props} info={hiddenInHome} />} />
          {
            menuItems.map (item => <Route key={item.path} exact path={item.path} render={
              (props) => {

                if (item.type === 'component')
                  return <Route 
                    path={item.path}
                    key={item.path}
                    render={ () => React.createElement( Components[item.Component], {...item.attributes, ...props} ) } 
                  />

                return item.pagination ? 
                <TabellaPaginata {...props} url={item.url} title={item.title} label={item.label} extraColumns={item.extraColumns} /> 
                : <Tabella {...props} url={item.url} title={item.title} label={item.label} extraColumns={item.extraColumns} />}
              } />)
          }
      </Container>
    </HashRouter>
    <div style={{paddingBottom: 40}}>&nbsp;</div>
    <div className="text-center fixed-bottom bg-dark text-white lead">
      <small>Software realizzato dal CIAM - UNIME nel 2020</small>
    </div>
  </>
  );
}

export default App;