import React , {useState} from 'react'
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy } from 'react-table'
import {filterGreaterThan, 
    fuzzyTextFilterFn, GlobalFilter, 
    DefaultColumnFilter,  

} from './filters'
import moment from 'moment'
import {Table, Pagination, PaginationItem, PaginationLink,
   Input, InputGroup, InputGroupText, InputGroupAddon, Spinner, Badge, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import {tableColumns} from './config'
// import convertData from './helper'

axios.defaults.timeout = 25000; // timeout necessario per non bloccare l'App

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val


function Tabella(props) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [lastModified, setLastModified] = useState("")

  React.useEffect( () => {
    axios.get(props.url)
      .then( res => {
        setLastModified(moment.utc(res.headers['last-modified'], "ddd, D MMM YYYY HH:mm:ss GMT").local().format('DD/MM/YYYY - [alle ore] HH:mm'))
        return res.data.data}
        )
      .then( remapped => {setData(remapped) })
      .then (() => setLoading(false))
      .catch( (err) => {
        setLoading(false); 
        console.error(err)} )
  }, [props])

  const RowPagination = () => {
    return <Col>
        <p>In totale ci sono {rows.length} righe -  Pagina{' '}
            <strong>
              {pageIndex + 1} di {pageOptions.length}
            </strong>
          </p>
        <Pagination>
          <PaginationItem>
            <PaginationLink first  onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
          </PaginationItem>
          <PaginationItem>
          <PaginationLink previous onClick={() => previousPage()} disabled={!canPreviousPage} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink next onClick={() => nextPage()} disabled={!canNextPage}/>
          </PaginationItem>
          <PaginationItem>
          <PaginationLink last  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
          </PaginationItem>
          <PaginationItem>
            <div style={{width: 10}}>
              &nbsp;
            </div>
          </PaginationItem>
        </Pagination>  
        <Pagination>
        <PaginationItem>
                <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    Record per pagina
                    </InputGroupText>
                </InputGroupAddon>
                  <div style={{width: 90}}>
                    <Input type="select"  
                            value={pageSize}
                            onChange={e => {
                              setPageSize(Number(e.target.value))
                            }}
                          >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            ))}
                          </Input>
                  </div>
                  </InputGroup>
                </PaginationItem>
                <PaginationItem>
            <div style={{width: 10}}>
              &nbsp;
            </div>
          </PaginationItem>
          <PaginationItem>
          <InputGroup>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>Vai alla pag:</InputGroupText></InputGroupAddon>
                    <span style={{width: 60}}>
                      <Input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                      }}
                    />
                      </span>
                  </InputGroup>
                </PaginationItem>
        </Pagination>
        </Col>
  }


  const columns = React.useMemo( (extra = props.extraColumns) => tableColumns(extra), [props])
  
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter}),[])

  const {
    getTableProps, getTableBodyProps, headerGroups, rows,
    prepareRow, allColumns, preGlobalFilteredRows, setGlobalFilter,
    state, page,
    canPreviousPage, canNextPage,
    pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize,
    state: { pageIndex, pageSize },
  } = useTable( {columns, data, defaultColumn, filterTypes },
    useFilters, 
    useGlobalFilter, 
    useSortBy,
    usePagination,
  )

  if(loading)
  return <div className="text-center">
        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /> 
        <p className="lead">Caricamento dati. Attendere, prego.</p>
    </div>

  return (
    <>     
        <Row>
          <Col className="text-center">
            <h1 className="text-primary" style={{paddingTop: '.5em', paddingBottom: '1em'}}>{props.title}</h1>
          </Col>
        </Row>
        <Row>
       <RowPagination />
        <Col md={5} xs={12}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
       </Col>
      </Row>
      <Row >               
        <Table {...getTableProps()} striped bordered className="table-responsive">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    { column.render('Header') }
                    <Badge {...column.getHeaderProps(column.getSortByToggleProps())} color="light" pill>
                    {column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon icon={faSortDown} />
                          : <FontAwesomeIcon icon={faSortUp} />
                        : <FontAwesomeIcon icon={faSort} />}
                    </Badge>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
            <tr scoped="row">
              <th
                colSpan={allColumns.length}
                style={{
                  textAlign: 'left',
                }}
              >
              </th>
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell', { editable: false })}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        </Table>
      <br />
        <RowPagination />
      
        <br />
        <br />
      </Row>
      <Row >
          <Col className="text-center">
            <h5>Ultimo aggiornamento: {lastModified}</h5>
          </Col>
      </Row>
    </>
  )
}

export default Tabella