// CONFIG FILE
import React from 'react'
import {Button} from 'reactstrap'
//import moment from 'moment'

const grassetto  = (data) => {
  return <b>{data.cell.value.toString()}</b> 
}

const LinkMoodle = (data) => {
  const {moodle, unhashValue} = data.cell.row.original

 return  <>{data.cell.value} {moodle===true ?<><br />
 <Button color="link"  href={`
 https://moodle2.unime.it/go/unicod?to_moodle=${unhashValue.split(" ")[0]}`} target="_blank">
   <i className="fa fa-external-link-alt"></i> Corso su piattaforma Moodle</Button></> : ""}
 </>
}

const ButtonUrl = (data) => {
  
  if(data.cell.value === 0) {
    console.log(data.cell)
  }

  return <>
    <Button color="success" href={data.cell.value} target="_blank">Partecipa</Button>
  </>
}
function reMap (data) {
  return data;
  /*
  return data.filter( item => {
  const {
      settCod, 
      desTipoCiclo, 
      dipAfDes, 
      nomeCds,
      partStuDes, 
      pdsDes,
      coperPeso,
      ambitoDes,
      afGenDes,
      annoCorso,
      afGenCod,
      listaOreCoper,
      cdsordCod
     } = item

  item.ambito = ambitoDes
  item.ssd = settCod
  item.dipartimento = dipAfDes
  item.corso = cdsordCod + " - " + nomeCds
  item.sede_part = pdsDes + " " + (partStuDes || "")
  item.insegnamento = afGenCod + " " + afGenDes
  item.periodo = desTipoCiclo
  item.cfu = coperPeso
  item.percorso = pdsDes
  item.anno = annoCorso
  item.ore = listaOreCoper

  return item 
  
 })*
  */
}

const GroupShow = (data) => {
 const {groupId, displayName} = data.cell.row.original

 return <>
 {displayName} <br /> 
 {groupId ? <small style={{color: "grey"}}>Group-id: {groupId}</small> : ""}
 </>
}
/*
const CorsoShow = (data) => {
  const {cdsCod, cdsDes} = data.cell.row.original
 
  return <>
  {cdsCod} - {cdsDes} 
  </>
 
 }
const DipShow = (data) => {
  const {dipCod, dipDes} = data.cell.row.original

  return <>
  {dipCod} - {dipDes} 
  </>
 
 }
 */
// Lezioni
const defaultColumns = [
 {
     Header: "Unime - Teledidattica",
     columns: [
       {
         Header: 'Dipartimento',
         accessor: 'displayDip',
         //Cell : data => DipShow(data)
       },
         {
         Header: 'Corso',
         accessor: 'displayCds',
        // Cell : data => CorsoShow(data)
         },
         {
           Header: 'Insegnamento',
           accessor: 'displayName',
           Cell : data => GroupShow(data)
           
         },
     {
         Header: 'Docente',
         accessor: 'docenti',
         Cell: (cell) => grassetto(cell),

       },
       {
         Header: 'Info',
         accessor: 'description',
         Cell: (val) => LinkMoodle(val)
       },
       {
         Header: 'Aula Teams',
         accessor: 'url',
         Cell: (url) => ButtonUrl(url),
         filterable: false,
         disableFilters: true,

         },       
   ],
 },
]


// Esami
const extraColumns = [
  {
      Header: "Unime - Teledidattica",
      columns: [
        {
          Header: 'Dipartimento',
          accessor: 'dipDes',
        },
          {
          Header: 'Corso',
          accessor: 'cdsDes',

          },
          {
            Header: 'Insegnamento',
            accessor: 'displayName',
            Cell : data => GroupShow(data)
          },
      {
          Header: 'Docente',
          accessor: 'docenti',
          Cell: (cell) => grassetto(cell),

        },
        {
          Header: 'Info',
          accessor: 'description',
        },
        {
          Header: 'Aula',
          accessor: 'url',
          Cell: (url) => ButtonUrl(url)
          },       
    ],
  },
]

function tableColumns(extra = false) {
  // qualsiasi cosa restituisce è il template da usare per la tabella.
  if(extra) {
    return extraColumns
  }
  return defaultColumns
}


export {tableColumns}
export default reMap