import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Badge,
  Nav,
  NavItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  NavLink } from 'reactstrap';
import {InfoWidget}  from '../index'
import '@fortawesome/fontawesome-free/css/all.css';

const  NavBarMenu = ({menuItems} ) => {

    const [modal, setModal] = React.useState(false)
    const [isOpen, setNav] = React.useState(false)
    const toggle = () => setModal(!modal);
    const toggleNav = () => setNav(!isOpen);
    const menuLink = (
        <>
        { menuItems.map( item => item.path.startsWith('https')?<a className="nav-link" href={item.path}>{item.label}</a>:<NavItem key={item.path}>
          <RouterNavLink to={item.path} className="nav-link" exact>{item.label}</RouterNavLink>
        </NavItem>
          )}
        <NavItem>
          <NavLink target="_blank" 
            href="https://teams.microsoft.com/l/channel/19%3a6ebacdd05f0c49ba80ca8cb9c46783af%40thread.tacv2/Generale?groupId=ff126e0a-dc68-43b2-b29e-1f0839cc0001&tenantId=84679d45-8346-4e23-8c84-a7304edba77f">
              Biblioteche
          </NavLink>
        </NavItem>
        </>
     );
    //}

    return (
      <div style={{paddingLeft: 24, paddingRight: 24}}>
        <Navbar color="dark" dark expand="md" fixed="top" >
          <NavbarBrand href={window.location.origin}>
              <span style={{paddingRight: 20}} 
              className="d-none d-md-inline">
                <img src={`${window.location.origin}/assets/logo_UniME_bw_lr.png`} height={38} alt="unime logo"/>
              </span>
                Fad Unime
           </NavbarBrand>

            <NavbarToggler onClick={toggleNav} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                {menuLink}
              </Nav>
              <Nav className="justify-content-end" navbar>
                <NavItem>
                  <span onClick={()=>{toggle()}}>
                    <Badge style={{padding: 8}}>
                      <a href="/#" style={{color:"white", textDecoration:'none'}}><i className="fa fa-question"></i>{" "}Guide</a>
                    </Badge>
                  </span>
                </NavItem>
              </Nav>
            </Collapse>
        </Navbar>

        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Guide & Informazioni</ModalHeader>
          <ModalBody>
            <Container>
              <InfoWidget size="sm" title={false}/>
            </Container>
            
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
      </Modal>

      </div>
    );
  }
export default NavBarMenu
