import React , {useState, useCallback, useMemo} from 'react'
import axios from 'axios'
import reMap, {tableColumns } from './config'
// import convertData from './helper'
import DataTable from './tabella'

axios.defaults.timeout = 30000; // timeout necessario per non bloccare l'App

function TabellaPaginata(props) {

  //const [lastModified, setLastModified] = useState("...in aggiornamento...")
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState({records: 0, pages: 0})
 
  const columns = useMemo((type = props.type, actions = props.actions) => tableColumns(type,actions), [props])
  const {url} = props


  function FetchCount (url, token){
    const options = {} || { headers: ({ 'Authorization': `Bearer ${token}` })}
    return axios.get(`${url}`, options )
  }

  const fetchData = useCallback(({pageIndex, pageSize, filters, token }) => {

//    const trash = trashed ? '_ne=0' :'_ne=1'

    let filter = ""
 //   let sort = (sortBy && sortBy.length) ? '&_sort=' + sortBy[0].id + ":" + (sortBy[0].desc ? "DESC" : "ASC") : ""

    for (let item of filters) {
      filter += `&${item.id}_contains=${item.value}`
    }
/*
    if(globalFilter) {
        filter='&'+globalFilter
    }
*/
    //const newUrl = `${url}?_limit=${pageSize}&_start=${pageIndex}&trash${trash}${filter}${sort}`

    //const newCountUrl = `${url}/count?trash${trash}${filter}`

    const newUrl = `${url}?_limit=${pageSize}&_start=${pageIndex*pageSize}${filter}` //${sort}`

    const newCountUrl = `${url}/count?${filter}`
     
    FetchCount (newCountUrl,token)
    .then( (resp) =>{
      const records = resp.data

      const options = {} //token ? {headers: ({ 'Authorization': `Bearer ${token}` })} : {}

      axios.get(newUrl, options)
          .then( res => reMap(res.data, props.type))
          .then( remapped => {
            setPageCount({records: records, pages: Math.ceil(records / pageSize)})
            setData(remapped)
      })
      .catch( (err) => {
        console.error(err)} )
    } )
  }, [props.type, url])
  
//  React.useEffect( () => FetchCount(url + '/count', props.token), [])

return <DataTable 
      {...props}
      data={data} 
      fetchData={fetchData} 
      //lastModified={lastModified} 
      pageCount={pageCount}
      columns={columns}
    /> 
}

export default TabellaPaginata