import React from 'react'
import {Row, Col, Table} from 'reactstrap'
import {ElencoLinks} from '../config'


const ElencoLezioni = () => {
 return <Row>
     <Col>
        <Table>
        <thead>
        <tr>
          <th>#</th>
          <th>Dipartimento</th>
        </tr>
      </thead>
      <tbody>
        {ElencoLinks.map( (item, idx) => <tr key={idx}>
          <th scope="row">{idx + 1}</th>
          <td><a href={item.link} target="_blank" rel="noopener noreferrer"><i className="fa fa-download"></i> {item.dipartimento}</a></td>
        </tr>)}
      </tbody>
        </Table>
     </Col>
 </Row>

}
export default ElencoLezioni