// CONFIG FILE
import React from 'react'
import {SelectColumnFilter} from './filters'
import {Button} from 'reactstrap'
import moment from 'moment'


const grassetto  = (data) => {
   return <b>{data.cell.value}</b> 
}

const ButtonUrl = (data) => {
  if(data.cell.value === 0) {
    console.log(data.cell)
  }
  return <Button color="success" href={data.cell.value} target="_blank">Partecipa</Button>
}

function reMap (data) {
  
     return data.filter( item => {
     const {
         settCod, 
         desTipoCiclo, 
         dipAfDes, 
         nomeCds,
         partStuDes, 
         pdsDes,
         coperPeso,
         ambitoDes,
         afGenDes,
         annoCorso,
         afGenCod,
         listaOreCoper,
         cdsordCod
        } = item

     item.ambito = ambitoDes
     item.ssd = settCod
     item.dipartimento = dipAfDes
     item.corso = cdsordCod + " - " + nomeCds
     item.sede_part = pdsDes + " " + (partStuDes || "")
     item.insegnamento = afGenCod + " " + afGenDes.replace("AA 19/20", "");
     item.periodo = desTipoCiclo
     item.cfu = coperPeso
     item.percorso = pdsDes
     item.anno = annoCorso
     item.ore = listaOreCoper

     return item 
     
    })
}

const GroupShow = (data) => {
    const {GroupId, TeamsName} = data.cell.row.original
    
    return <>
    {
      // TODO: il replace dovrà essere fatto sul DataBase.
    }
    {TeamsName.replace("AA 19/20", "")} <br /> 
    <small style={{color: "grey"}}>Group-id: {GroupId}</small>
    </>
  
}

// Lezioni
const defaultColumns = [
    {
        Header: "Unime - Teledidattica",
        columns: [
          {
            Header: 'Dipartimento',
            accessor: 'dip_des',
            Filter: SelectColumnFilter,
            filter: 'equals'
          },
            {
            Header: 'Corso',
            accessor: 'cds_des',

            },
            {
              Header: 'Insegnamento',
              accessor: 'TeamsName',
              Cell : data => GroupShow(data)
              
            },
        {
            Header: 'Docente',
            accessor: 'docente',
            Cell: (cell) => grassetto(cell),

          },
          {
            Header: 'Info',
            accessor: 'TeamsDesc',
          },
          {
            Header: 'Aula',
            accessor: 'url',
            Cell: (url) => ButtonUrl(url)
            },       
      ],
    },
]

// Esami
const extraColumns = [
  {
      Header: "Unime - Teledidattica",
      columns: [
        {
          Header: 'Dipartimento',
          accessor: 'dip_des',
          Filter: SelectColumnFilter,
          filter: 'equals'
        },
          {
          Header: 'Corso',
          accessor: 'cds_des',

          },
          {
            Header: 'Insegnamento',
            accessor: 'TeamsName',
            Cell : data => GroupShow(data)
          },
      {
          Header: 'Docente',
          accessor: 'docente',
          Cell: (cell) => grassetto(cell),

        },
        {
          Header: 'Info',
          accessor: 'TeamsDesc',
        },
        {
          Header: 'Ora Inizio',
          accessor: 'ora_inizio',
          Cell: (cell) => cell.value ? cell.value.substr(0,5)  : ""
        },
        {
          Header: 'Data',
          accessor: 'data_inizio',
          Cell: (cell) => cell.value ?  moment(cell.value).format('DD/MM/YYYY') : ""
        },
        {
          Header: 'Aula',
          accessor: 'url',
          Cell: (url) => ButtonUrl(url)
          },       
    ],
  },
]

function tableColumns(extra = false) {
  // qualsiasi cosa restituisce è il template da usare per la tabella.
  if(extra) {
    return extraColumns
  }
  return defaultColumns
}

export {tableColumns}
export default reMap