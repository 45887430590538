const basePath = 'https://fad-teams-api.goto.unime.it/lezioni/' //window.location.origin + window.location.pathname

const menuItems = [
  /*{
    path : '/esami',
//    extraColumns: true,
//    visibilityInHome: 'hidden',
    label: 'Esami CdS',
    title: 'Esami CdS',
    url: `${basePath}esami`,
    category: 'Esami',
    text: 'success'
  },*/
  {
    type: "component",
    path: 'https://minerva2.unime.it/fadLezioni',
    label: 'Lezioni',
    title: 'Lezioni',
    Component: 'ElencoLezioni',
    attributes: ""
  },
  /*{
    path : '/scuole',
    label: "Scuole di Specializzazione",
    title: "Scuole di Specializzazione",
    url: `${basePath}scuole`,
    category: 'Lezioni',
    text: 'primary'
  },*/
  {
    path : 'https://minerva2.unime.it/fad/sostegno/',
    label: "Sostegno",
    title: "Sostegno",
    category: 'Lezioni',
    text: 'primary'
  },
  {
    path : 'https://minerva2.unime.it/fad/24cfu',
    label: "30 CFU",
    title: "30 CFU",
    url: `${basePath}24cfu`,
    category: 'Lezioni',
    text: 'primary'
  },
  {
    path : 'https://minerva2.unime.it/fad/lettori',
    label: 'Lettori',
    title: 'Lettorati e Lab. Linguistici',
    url: `${basePath}lettori`,
    category: 'Lezioni',
    text: 'primary'
  },
  /*{
    path : '/seminari',
    extraColumns: true,
    label: 'Seminari / Tirocini',
    title: 'Seminari / Tirocini',
    url: `${basePath}seminari`,
    category: 'Lezioni',
    text: 'primary'
  },
  {
    path : '/master',
    extraColumns: true,
    label: 'Master',
    title: 'Master',
    url: `${basePath}master`,
    category: 'Lezioni',
    text: 'primary'
  },
  */
]

export const ElencoLinks = [
  {
      dipartimento: "Civiltà antiche e moderne",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EUyj9KV0hB9IkSWn89zY5PUBhWJM94ZhP41K_ZZ7ytGUpg?e=uRhid9"
  },
  {
      dipartimento: "Economia",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EehsQYn2_0tIhacQw5XP3FgBCbdY6BiFNlDtyLA1TT_RJQ?e=QmntdY"
  },
  {
      dipartimento: "Giurisprudenza",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EdH3jmmaWW5PlNV0N0ZiR_UBT_w1IuSrfE433O_ftTdvVA?e=oz7Xif"
  },
  {
      dipartimento: "Ingegneria",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EY1os6MiX1RNm8WwhnUUUvoBsSO49m_v3nK0SE00V4uE9Q?e=CVU9Nh"
  },
  {
      dipartimento: "Medicina clinica e sperimentale",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/ESxaTTTywaROkWBPN7U5LSwB_5Z-f0BzQKVnM71NziPgKQ?e=DOpVml"
  },
  {
      dipartimento: "Patologia umana dell'adulto e dell'età evolutiva 'Gaetano Barresi'",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EUsvEMwvcCdHm1mFhelCRSwBnGyGOYkEqJEvQ-oHVUNfZg?e=pj8b47"
  },
  {
      dipartimento: "Scienze biomediche, odontoiatriche e delle immagini morfologiche e funzionali",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EYflYXs8i6VFjxNBbmGbHUUBkrdpEebcv0AGFCmTZgnIjQ?e=Ja48aa"
  },
  {
      dipartimento: "Scienze chimiche, biologiche, farmaceutiche ed ambientali",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EXkABPvjjmxLifN3iX5LzCkBAnICwPyfM55NbVJN-n2f-g?e=K2thnW"
  },
  {
      dipartimento: "Scienze cognitive, psicologiche, pedagogiche e degli studi culturali",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EXLdOEZypOhPs0LkcRi1NnUBn1C99HkDpqX1V5NX7yF2xg?e=axsgfp"
  },
  {
      dipartimento: "Scienze matematiche e informatiche, scienze fisiche e scienze della terra",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/Ea9KVx6HlK5OghgSfSK-feoBATewKTRLSs09tXCx_yDTgA"
  },
  {
      dipartimento: "Scienze politiche e giuridiche",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EV0ftXRn3lpLpLt14iRjXqoBkOrKe_jINUi4uYpHirrg6w?e=lAu8sV"
  },
  {
      dipartimento: "Scienze veterinarie ",
      link: "https://unimeit-my.sharepoint.com/:x:/g/personal/teams2_ciam_unime_it/EW9Bgiaim0xIrM411ecqD9oBLFKH2uTQcO4N3YdvoyI_hw"
  }
]

export default menuItems;

