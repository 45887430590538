import React, {useState} from 'react'
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy, useAsyncDebounce } from 'react-table'
import {filterGreaterThan, 
    fuzzyTextFilterFn, 
    DefaultColumnFilter} from './filters'

import {Table, Pagination, PaginationItem, PaginationLink,
   Input, InputGroup, InputGroupText, InputGroupAddon, Spinner, Badge, Row, Col,   } from 'reactstrap'

   
//import {tableColumns} from './config'
// import convertData from './helper'

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number

filterGreaterThan.autoRemove = val => typeof val !== 'number';

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

export default function DataTable(props) {
  const skipPageResetRef = React.useRef()
  const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter}),[])
  const [loading, setLoading] = useState(true)
  const [internalLoading, setInternalLoading] = useState(false)
  //const [trashed, setTrashed] = useState(false)
  const trashed = false

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )


const {
  columns,
  data,
  fetchData,
  //lastModified,
  token,
  pageCount: {records: totalRecords, pages: controlledPageCount}
} = props

  const {
    allColumns, 
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    //preGlobalFilteredRows,
    //setGlobalFilter,
    setPageSize,
    //state,
    // Get the state from the instance
    state: { 
      pageIndex, 
      pageSize,
      sortBy,
      groupBy,
      filters,
      globalFilter
    },
  } = useTable(
    {
      columns, data, initialState: { pageIndex: 0 }, 
      defaultColumn, filterTypes,
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      manualPagination: true, 
      manualFilters: true,
      manualGlobalFilter: true,
      manualSortBy:true,
      pageCount: controlledPageCount
    },
    useFilters, 
    useGlobalFilter, 
    useSortBy,
    usePagination,
  )

 // Debounce our onFetchData call for 1000ms
  const fetchDataDebounced = useAsyncDebounce(fetchData, 1000)

  React.useEffect(() => {
    console.log('calling')
    skipPageResetRef.current = true
     setInternalLoading(true)
    fetchDataDebounced({ pageIndex, pageSize,  filters, globalFilter, groupBy, sortBy, token, trashed})
    .then( () => { setLoading(false); setInternalLoading(false)})
  }, [fetchData, pageIndex, filters, pageSize, globalFilter, groupBy, sortBy, trashed, token, fetchDataDebounced])


  const RowPagination = () => {
    return <Col>
        <p>In totale ci sono {totalRecords} righe -  Pagina{' '}
            <strong>
              {pageIndex + 1} di {pageOptions.length}
            </strong>
          </p>
        <Pagination>
          <PaginationItem>
            <PaginationLink first  onClick={() =>  { gotoPage(0)}} disabled={!canPreviousPage} />
          </PaginationItem>
          <PaginationItem>
          <PaginationLink previous onClick={() =>  { previousPage()}} disabled={!canPreviousPage} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink next onClick={() =>  { nextPage()}} disabled={!canNextPage}/>
          </PaginationItem>
          <PaginationItem>
          <PaginationLink last  onClick={() =>  {  gotoPage(pageCount - 1)}} disabled={!canNextPage} />
          </PaginationItem>
          <PaginationItem>
            <div style={{width: 10}}>
              &nbsp;
            </div>
          </PaginationItem>
        </Pagination>  
        <Pagination>
        <PaginationItem>
                <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    Record per pagina
                    </InputGroupText>
                </InputGroupAddon>
                  <div style={{width: 90}}>
                    <Input type="select"  
                            value={pageSize}
                            onChange={e => {
                              setInternalLoading(true); 
                              setPageSize(Number(e.target.value))
                            }}
                          >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            ))}
                          </Input>
                  </div>
                  </InputGroup>
                </PaginationItem>
                <PaginationItem>
            <div style={{width: 10}}>
              &nbsp;
            </div>
          </PaginationItem>
          <PaginationItem>
          <InputGroup>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>Vai alla pag:</InputGroupText></InputGroupAddon>
                    <span style={{width: 60}}>
                      <Input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={e => {
                        setInternalLoading(true); 
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                      }}
                    />
                      </span>
                  </InputGroup>
                </PaginationItem>
        </Pagination>
        </Col>
  }


  //const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter}),[])
  if(loading)
    return <div className="text-center">
          <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /> 
          <p className="lead">Caricamento dati. Attendere, prego.</p>
      </div>


  return (
    <>     
      <Row>
       <RowPagination {...props}/>
      </Row>
      <Row >               
        <Table {...getTableProps()} striped bordered className="table-responsive">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    { column.render('Header') }
                    <Badge {...column.getHeaderProps(
                      //column.getSortByToggleProps()
                      )} color="light" pill>
                    {/*column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon icon={faSortDown} />
                          : <FontAwesomeIcon icon={faSortUp} />
                    : <FontAwesomeIcon icon={faSort} /> */}
                    </Badge>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
            <tr scoped="row">
              <th
               colSpan={allColumns.length}
                style={{
                  textAlign: 'left',
                }}
              >
              </th>
            </tr>
          </thead>
          {
            internalLoading ? <thead><tr className="text-center"><td>
              <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} /> 
            </td>   
        </tr></thead> :
          <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell', { editable: false })}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>}
        </Table>
        <br />
        <RowPagination />
        <br />
        <br />
      </Row>
    </>
  )
}

